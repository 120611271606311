document.addEventListener('DOMContentLoaded', function (event) {

  cookieConsent();

  // array with texts to type in typewriter
  var dataText = ["CN-OSS"];

  // type one text in the typewriter
  // keeps calling itself until the text is finished
  function typeWriter(text, i, fnCallback) {
    // check if text isn't finished yet
    if (i < (text.length)) {
      // add next character to h1
      let headline =document.querySelector("h1")
      if (headline == null) {
        return
      }
      headline.innerHTML = text.substring(0, i + 1) + '<span aria-hidden="true"></span>';

      // wait for a while and call this function again for next character
      setTimeout(function () {
        typeWriter(text, i + 1, fnCallback)
      }, 100);
    }
    // text finished, call callback if there is a callback function
    else if (typeof fnCallback == 'function') {
      // call callback after timeout
      // setTimeout(fnCallback, 700);
    }
  }

  // start a typewriter animation for a text in the dataText array
  function StartTextAnimation(i) {
    if (typeof dataText[i] == 'undefined') {
      setTimeout(function () {
        StartTextAnimation(0);
      }, 20000);
    }
    // check if dataText[i] exists
    if (i < dataText[i].length) {
      // text exists! start typewriter animation
      typeWriter(dataText[i], 0, function () {
        // after callback (and whole text has been animated), start next text
        StartTextAnimation(i + 1);
      });
    }
  }

  // start the text animation
  StartTextAnimation(0);
});

function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/;SameSite=Lax";
}
function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)===' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}
function cookieConsent() {
    if (!getCookie('allowCookies')) {
      $('.toast').toast('show')
    } else {
      showVideos();
    }
}
function eraseCookie(name) {
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

$('#btnDeny').click(()=>{
    eraseCookie('allowCookies')
    hideVideos();
    $('.toast').toast('hide')
})

function hideVideos() {
  $(".iframe_yt").remove();
}

function showVideos() {
      $(".yt_vid").each(function () {
      $('<iframe>', {
        class: 'iframe_yt',
        src: 'https://www.youtube.com/embed/' + $(this).attr('data-src'),
        style: "width: 100%; height: 100%",
        title: $(this).attr('data-title'),
        allowfullscreen: ""
      }).appendTo(this);
    })
}

$('#btnAccept').click(()=>{
    setCookie('allowCookies','1',7)
    showVideos();
    $('.toast').toast('hide')
})
